// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal-terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-our-services-design-concept-and-space-planning-tsx": () => import("./../../../src/pages/our-services/design-concept-and-space-planning.tsx" /* webpackChunkName: "component---src-pages-our-services-design-concept-and-space-planning-tsx" */),
  "component---src-pages-our-services-index-tsx": () => import("./../../../src/pages/our-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-index-tsx" */),
  "component---src-pages-our-services-project-implementation-and-delivery-tsx": () => import("./../../../src/pages/our-services/project-implementation-and-delivery.tsx" /* webpackChunkName: "component---src-pages-our-services-project-implementation-and-delivery-tsx" */),
  "component---src-pages-our-services-workplace-evaluation-tsx": () => import("./../../../src/pages/our-services/workplace-evaluation.tsx" /* webpackChunkName: "component---src-pages-our-services-workplace-evaluation-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/projectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */)
}

